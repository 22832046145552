import { useEffect, useState } from 'react';

import axios, { AxiosResponse } from 'axios';
import { NavLink } from 'react-router-dom';

import BaseLayout from '../base/BaseLayout';

import Logo from '../components/logo/Logo';
import Navbar from '../components/navbar/Navbar';
import LogoMain from './../../assets/img/logo.svg';
import LogoAppColmVert from './../../assets/img/logo-columnvert.svg';

import Menu from '../components/menu/Menu';
import NavLoader from '../components/navbar/NavLoader';
import AddPatient from '../../pages/patients/AddPatient';

import Actions from '../components/actions/Actions';
import { toggleSidebar } from '../../redux/settings/actions';

import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from '../../interfaces/app-state';

import './Vertical.scss';
import { IsDiscapacidadProject, ShowOptionAddPatient, USER_INFO, _APP_ID } from '../../data/General';

type Props = {
  children: any;
};


const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);

  // const searchData = useSearchData();

  const onSidebarToggle = () => dispatch(toggleSidebar());

  const [menuData, setMenuData] = useState([]);

  const logoSvg = IsDiscapacidadProject() ? LogoAppColmVert : LogoMain;

  useEffect(() => {
    async function fetchMenuData() {
      let result: AxiosResponse<any, any>;
      if (IsDiscapacidadProject()) {
        result = await axios('/data/menu_disc_' + USER_INFO.typeUser + '.json');
      } else {
        result = await axios('/data/menu.json');
      }

      setMenuData(result.data);
    }

    fetchMenuData().catch((err) => console.log('Server Error', err));
  }, []);

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span />
        <span />
        <span />
      </button>

      {/* <Search /> */}

      <Actions />

      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const OnClickWebPage = () => {
    if (!IsDiscapacidadProject())
      window.open("https://drpedroreyes.com", '_blank');
  }

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Logo src={logoSvg} />

      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />

      {ShowOptionAddPatient() &&
        <AddPatient />}

      <Menu className='assistant-menu' orientation='vertical'>

        {/* FIXME: VALIDAR  activeClassName */ /*activeClassName='active'*/}
        {/* <NavLink className='link' to='#' replace onClick={OnClickWebPage}> */}
        <NavLink
          to='#'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace onClick={OnClickWebPage}>
          <span className='link-icon icofont icofont-question-square' />

          <span className='link-text'>Página WEB</span>
        </NavLink>

      </Menu>

      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;