import { ReactNode, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Footer from '../components/footer/Footer';

import { fetchPatients } from '../../redux/patients/actions';

import className from '../../utils/class-names';

import { IAppSettings } from '../../interfaces/settings';
import { APP_IDENTIFIER, IAppState } from '../../interfaces/app-state';
import { IPageData } from '../../interfaces/page';

import './BaseLayout.scss';
import Search from '../components/search/Search';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { _APP_ID } from '../../data/General';


type Props = {
  nav: ReactNode;
  sideNav?: ReactNode;
  topNav?: ReactNode;
  children: ReactNode;
  orientation: 'vertical' | 'horizontal';
};

const BaseLayout = ({ nav, topNav, sideNav, orientation, children }: Props) => {
  const [showSettings, setShowSettings] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const dispatch = useDispatch();

  const sidebarOpened = useSelector<IAppState, boolean>((state) => state.settings.sidebarOpened);
  const settings = useSelector<IAppState, IAppSettings>((state) => state.settings);
  const pageData = useSelector<IAppState, IPageData>((state) => state.pageData);

  useEffect(() => {
    if (_APP_ID == APP_IDENTIFIER.DR_ADMIN) {
      dispatch(fetchPatients());
    }
  }, [dispatch]);

  const handleScroll = (event) => {
    setScrolled(event.target.scrollTop > 0);
    if (pageData.OnScroll) pageData.OnScroll(event.target.scrollTop > 0);
  };

  const mainContentClasses = className({
    'main-content': true,
    loaded: pageData.loaded,
    fulfilled: pageData.fulFilled
  });

  const mainContentWrapClasses = className({
    // 'main-content-wrap': true
    'main-content-wrap': !pageData.isFullScreen
  });

  const mainHeaderWrapClass = className({
    'page-header': true,
    'main-content-wrap': !!pageData.isFullScreen
  });

  const toggleSettings = () => setShowSettings(!showSettings);

  const contentOverlay = (
    <div
      className={className({
        'content-overlay': true,
        show: sidebarOpened
      })}
    />
  );

  let history = useNavigate();

  const titleButtonLabel = (
    (<Button icon={<LeftOutlined />} type="link" className='button-back' onClick={() => history(-1)}>{pageData.title}</Button>)
  );

  const titleLabel = (
    (<h1 className='page-title' style={{ width: "auto" }}>{pageData.title}</h1>)
  );

  const titleView = pageData.hasBack ? titleButtonLabel : titleLabel;

  return (
    <div className={`layout ${orientation}`}>
      <div className={`app-container ${settings.boxed && 'boxed'} ${scrolled && 'scrolled'}`}>
        {nav}

        {topNav}

        {sideNav}

        <main onScroll={handleScroll} className={mainContentClasses}>
          <div className='app-loader'>
            <i className='icofont-spinner-alt-4 rotate' />
          </div>

          <div className={mainContentWrapClasses}>
            {pageData && !!pageData.title && (
              <header className={mainHeaderWrapClass}>
                <div>
                  {titleView}
                </div>
                {!!pageData.OnChangeSearch && <Search OnSearchResult={pageData.OnChangeSearch} />}
              </header>
            )}
            {children}
          </div>
        </main>

        <Footer
          breadcrumbs={pageData.breadcrumbs}
          layout={settings.layout}
          boxed={settings.boxed}
          loaded={pageData.loaded}
          openModal={toggleSettings}
        />
        {contentOverlay}
      </div>
    </div>
  );
};

export default BaseLayout;
