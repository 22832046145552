import { Card, Col, Row, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import { IPatient } from '../../interfaces/patient';
import { useNavigate } from 'react-router-dom';

import './History.scss';
import React, { useState } from 'react';
import UserPreviewHeader, { AddInputsProfile } from './ExpedienteHeaderPatient';
import { AddCardRowItem, EQuestion, EQuestionGroup } from './IconPainEVA';
import { SectionQuestion, SetExpedienteQuestions, listExpPage1, questionsMainProfile } from './ExpedienteQuestions';
import OpenNotification from '../Toast';
import { IExpediente, IValueQuestion } from '../../interfaces/expediente';
import { SetExpedienteInfo } from '../../data/Expediente';
import PageAction from '../../layout/components/page-action/PageAction';
import { GetIMC, GetIMCDescription, SetScrollToTop } from './ExpUtils';
import { HistoryRedirect, IsDiscapacidadProject, IsHistoryDisabled, _APP_ID } from '../../data/General';
import { APP_IDENTIFIER } from '../../interfaces/app-state';
// import PDFViewerTest from '../../data/TestPDF';
import { SaveExpedientePDF } from './print/PrintNewBody';
import { DismissLoading, ShowLoading } from '../../hooks/useHideLoader';

const pageData: IPageData = {
    title: 'Expediente',
    fulFilled: true,
    hasBack: true,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        // {
        //     title: 'Historial',
        //     route: 'historial'
        // },
        {
            title: 'Expediente médico 2'
        }
    ]
};

const UserPreview = ({
    patient, disabled, expp
}: { patient: IPatient, disabled: boolean, expp: IExpediente }) => {
    SetScrollToTop();
    usePageData(pageData);

    const isEnableAdd = IsDiscapacidadProject() && (expp && expp.questionMain.length > 0 && !disabled);
    const descriptionStatus = !isEnableAdd && _APP_ID == APP_IDENTIFIER.DR_ADMIN ? "Agregado Nuevo Expediente" : null;

    return (
        <>
            <UserPreviewHeader patient={patient} enableBtnAdd={isEnableAdd} statusLeyend={descriptionStatus} />
            <AddInputsProfile />
        </>
    )
};

const CardEachItem = ({
    rowInfo,
    cardNumber
}: { rowInfo: EQuestionGroup[], cardNumber: number }) => {
    return (<React.Fragment key={cardNumber}>
        {
            rowInfo.map((itemRow, i) => {
                let identifier = cardNumber + "-" + i;

                return (
                    <div key={"div-row-card-" + identifier}>
                        <AddCardRowItem itemGRow={itemRow} idCard={identifier} OnUpdateText={CalculateIMC} />
                    </div>
                );
            })
        }
    </React.Fragment >)
}

const CalculateIMC = (itemRow: EQuestion, parentRow: EQuestionGroup) => {
    if (itemRow.id != 109 && itemRow.id != 110)
        return false;

    const _sect = listExpPage1[0];
    const _fndPesoTalla = _sect.rowInfo.find(o => o.questions.find(p => p.id == 109));
    // const _fndTalla = _sect.rowInfo.find(o => o.questions.find(p => p.id == 110));
    const _peso = _fndPesoTalla.questions.find(o => o.id == 109)
    const _talla = _fndPesoTalla.questions.find(o => o.id == 110)
    if (!_peso.value || !_talla.value) return false;

    const _fndImc = _fndPesoTalla.questions.find(o => o.id == 111);
    _fndImc.value = GetIMC(_peso, _talla);
    parentRow.descriptionValue = GetIMCDescription(_fndImc.value);

    return true;
}

const ColParImpar = (
    { listData }: { listData: SectionQuestion[] }
) => {
    return (<Col span={12}>
        {
            listData.map((item) => {
                return (
                    <Card title={item.title} className='with-shadow' key={item.id}>
                        <CardEachItem rowInfo={item.rowInfo} cardNumber={item.id} />
                    </Card>
                )
            })
        }
    </Col>)
}

const CardsModuleExp = () => {
    let pares = listExpPage1.filter(o => o.id % 2 == 1);
    let impares = listExpPage1.filter(o => o.id % 2 == 0);

    return (<>
        <Row gutter={15}>
            <ColParImpar listData={pares} />
            <ColParImpar listData={impares} />
        </Row>
    </>);
}


const ExpedientePage = () => {
    const history = useNavigate();
    const location = useLocation();
    const _objData = location.state || [];
    const patient = _objData[0];
    const _disabledEdition = IsHistoryDisabled();

    const [_expedienteInfo] = useState(() => SetExpedienteQuestions(_objData[1], patient, _disabledEdition));

    const layout = location.pathname.split('/')[1];

    const [_loading, setLoading] = useState(false)
    const [_scrollStatus, setScrollStatus] = useState(false);

    if (!patient || (Object.keys(patient).length === 0 && patient.constructor === Object)) {
        history(HistoryRedirect());
        return null;
    }

    pageData.OnScroll = (isTop: boolean) => setScrollStatus(isTop);

    const OnNextPage = () => {
        if (_disabledEdition) {
            history(`/${layout}/history-bodyzone`, { state: { patient: patient, expediente: _expedienteInfo }, replace: true });
            return;
        }

        const resultMainProfile: IValueQuestion[] = [];
        for (let i = 0; i < questionsMainProfile.length; i++) {
            const item = questionsMainProfile[i];
            for (let x = 0; x < item.questions.length; x++) {
                const subItem = item.questions[x];
                // const valItem = subItem.questions[z];
                if (!!subItem.value) {
                    resultMainProfile.push({ id: subItem.id, value: subItem.value })
                }
            }
        }

        const resultExpPage1: IValueQuestion[] = [];
        for (let i = 0; i < listExpPage1.length; i++) {
            const item = listExpPage1[i];
            for (let x = 0; x < item.rowInfo.length; x++) {
                const subItem = item.rowInfo[x];
                for (let z = 0; z < subItem.questions.length; z++) {
                    const valItem = subItem.questions[z];
                    if (!!valItem.value) {
                        resultExpPage1.push({ id: valItem.id, value: valItem.value })
                    } else {
                        // const msg = valItem.label ? "Rellena el campo: " + valItem.label : (subItem.title ? "Rellena el campo: " + subItem.title : "Rellena todos lo campos")
                        // OpenNotification('¡Información incompleta!', msg, 'error');
                        // return;
                    }
                }
            }
        }

        if (resultMainProfile.length == 0 || resultExpPage1.length == 0) {
            OpenNotification('¡Llenar todos los campos!', "Llena algunas de las cajas de texto", 'warn');
            return;
        }

        // OpenNotification('¡Información COMPLETA!', "COMPLETAOOOOOO", 'success');

        _expedienteInfo.questionMain = resultMainProfile;
        _expedienteInfo.questionPage1 = resultExpPage1;


        ShowLoading();
        setLoading(true);
        SetExpedienteInfo(_expedienteInfo, (result) => {
            setLoading(false)
            DismissLoading();
            if (result > 0) {
                _expedienteInfo.id = result;
                history(`/${layout}/history-bodyzone`, { state: { patient: patient, expediente: _expedienteInfo }, replace: true });
            } else {
                OpenNotification('¡Error de actualización!', "Ocurrió un error al actualizar el expediente", 'error');
            }

        })
    }

    const Print = () => {
        SaveExpedientePDF([patient, questionsMainProfile, listExpPage1]);
    }

    return (
        <>

            {/* <PDFViewerTest OPT='EXPEDIENTE' data={[patient, questionsMainProfile, listExpPage1]} /> */}

            <UserPreview patient={patient} disabled={_disabledEdition} expp={_expedienteInfo} />
            <CardsModuleExp />
            <div className='elem-list justify-content-between'>
                <Button className='bg-color-success' onClick={Print} >
                    <span className='text-color-500'>Imprimir</span>
                </Button>
                <Button type='primary' className='ml-auto' onClick={OnNextPage} loading={_loading}>
                    Siguiente
                </Button>
            </div>

            {_scrollStatus && <PageAction onClick={SetScrollToTop} icon='icofont-simple-up' type={'primary'} key="btn-up-scroll" />}
        </>
    )
}

export default ExpedientePage;