import React, { ChangeEvent, useState } from 'react';

import { useFormik } from 'formik';
import { Button, DatePicker, DatePickerProps, Divider, Form, FormInstance, Input, Modal, Switch } from 'antd';

import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { IPageData } from '../../../interfaces/page';
import { IStudent } from '../../interfaces/Student';
import OpenNotification from '../../../pages/Toast';
import { SetStudentData, _SetStudentInfo } from '../../Model/Student';
import { USER_INFO } from '../../../data/General';
import { ChangeUserPassword, RemoveUserData, SetLocalUserData } from '../../../data/User';
import { hasErrorFactory } from '../../../utils/hasError';
import { ETypeUser } from '../../../interfaces/user';
import { usePageData } from '../../../hooks/usePage';

import './student.scss'

const pageData: IPageData = {
  title: 'Editar alumno',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'home'
    },
    {
      title: 'Alumnos',
      route: 'students'
    },
    {
      title: 'datos alumno'
    }
  ]
};

const FormItem = Form.Item;

const userSchema = Yup.object().shape({
  email: Yup.string().required(),
  name: Yup.string().required(),
});

const AccountForm = (
  { user, fnUpdateUser }: {
    user: IStudent, fnUpdateUser: React.Dispatch<React.SetStateAction<IStudent>>
  }
) => {
  if (user.typeUser == ETypeUser.STUDENT) {
    const matriculaSchObj = Yup.object({ matricula: Yup.string().required() });
    userSchema.concat(matriculaSchObj);
  }

  const [submitted, setSubmitted] = useState({ ...user });
  const {
    values
    , setValues
    , handleBlur
    , errors
    , touched
    , isValid
  } = useFormik<IStudent>({
    validationSchema: userSchema,
    onSubmit: (values) => setSubmitted(values),
    initialValues: { ...user }
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
    values.birthDate = dateString;
  };

  const [genreVal, setGenreVal] = useState(user.gender == 1);

  const handleSelectChange = (name) => (value) => {
    if (name == 'gender') {
      value = value ? 1 : 0;
      setGenreVal(value);
    }

    setValues({ ...values, [name]: value });
  };

  const hasChanged = Object.keys(values).some((key) => values[key] !== submitted[key]);
  const dateFormat = 'YYYY-MM-DD';
  const dateNow = moment().format(dateFormat);

  const history = useNavigate();

  //Modal confirm config
  const [modal, contextHolder] = Modal.useModal();

  const GetModalConfig = (obj: IStudent) => {
    return {
      title: 'Eliminar estudiante!',
      content: (
        <>
          {`Deseas eliminar el estudiante: ${obj.name + ' ' + (obj.lastname ? obj.lastname : '')} ?`}
        </>
      ),
    };
  }

  //Update and Create user
  const handleSubmit = () => {
    if (values.gender !== 1 && values.gender !== 0) {
      OpenNotification('¡Error!', "Selecciona el genero del usuario", 'error');
      return;
    }

    let toUpdateUser = values.id && values.id > 0;

    SetStudentData(values, toUpdateUser, (result) => {
      if (result > 0) {
        OpenNotification(toUpdateUser ? '¡Actualizado!' : '¡Agregado!', "Se " + toUpdateUser ? "actualizó" : "agregó" + " el usuario");

        if (!toUpdateUser) {
          values.id = result;
          // history(-1);
          fnUpdateUser(values);
        } else {
          if (values.id === USER_INFO.id) {//Actualizar usuario logueado
            SetLocalUserData(values);
          }
        }
      } else {
        OpenNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    });
  }

  //Remove user..
  const handlerRemoveUser = async () => {

    const confirmed = await modal.confirm(GetModalConfig(values));
    if (confirmed) {
      RemoveUserData(values.id, (result) => {
        if (result > 0) {
          OpenNotification('¡Eliminado!', "Se eliminó el usuario");
          history(-1);
        } else {
          OpenNotification('¡Error!', "Ocurrió un error al eliminar", 'error');
        }
      })
    }
  }

  const hasError = hasErrorFactory(touched, errors);
  let canDelete = (values.id && values.id > 0) && values.id != USER_INFO.id;

  return (
    <Form layout='vertical'>
      {user.typeUser == ETypeUser.STUDENT && (USER_INFO.typeUser == ETypeUser.ADMIN || USER_INFO.typeUser == ETypeUser.ROOT) && <FormItem label='Matricula*'>
        <Input
          name='matricula'
          type='number'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Matricula'
          required
          defaultValue={values.matricula}
          className={hasError('matricula')} />
      </FormItem>}

      <FormItem label='Nombre*'>
        <Input
          name='name'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Nombre'
          required
          defaultValue={values.name}
          className={hasError('name')} />
      </FormItem>

      <FormItem label='Apellidos'>
        <Input
          name='lastname'
          onChange={handleChange}
          defaultValue={values.lastname}
          placeholder='Apellidos'
        />
      </FormItem>

      <FormItem label='Correo electrónico*'>
        <Input
          name='email'
          onChange={handleChange}
          defaultValue={values.email}
          placeholder='Correo electrónico'
          onBlur={handleBlur}
          className={hasError('email')}
          required />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Fecha de nacimiento'>
            <DatePicker
              name='birthDate'
              className={'ant-input ' + hasError('birthDate')}
              placeholder='Fecha de nacimiento'
              onChange={onChangeDate}
              defaultValue={values.birthDate ? moment(values.birthDate, dateFormat) : null as any}
              format={dateFormat}
              onBlur={handleBlur}
              disabledDate={d => !d || d.isAfter(dateNow)} />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Genero' className='check-genre'>
            <Switch defaultChecked={values.gender === 1} onChange={handleSelectChange('gender')} /> <span className='ml-2'>{genreVal ? 'Masculino' : 'Femenino'}</span>
          </FormItem>
        </div>
      </div>

      <FormItem label='Teléfono'>
        <Input
          type='number'
          name='phone'
          onChange={handleChange}
          defaultValue={values.phone}
          placeholder='Teléfono'
          onBlur={handleBlur}
          className={hasError('phone')} />
      </FormItem>

      <FormItem label='Dirección'>
        <Input.TextArea name="address" rows={4} defaultValue={values.address} onChange={handleChange} placeholder='Dirección' />
      </FormItem>

      <div className='elem-list justify-content-between'>
        <Button disabled={!isValid || !hasChanged} className='bg-color-success' onClick={handleSubmit}>
          <span className='text-color-500'>Guardar Cambios</span>
        </Button>

        {canDelete &&
          (<Button ghost danger className='ml-auto' onClick={handlerRemoveUser}>
            Eliminar cuenta
          </Button>)
        }
      </div>

      {contextHolder}

    </Form >
  );
};

const emptyPass = {
  id: 0,
  password: "",
  newPassword: "",
  confirmPassword: ""
};

interface iPass {
  id: number;
  password: string;
  newPassword: string;
  confirmPassword: string;
}




const PasswordForm = ({ user }: { user: IStudent }) => {
  const formRef = React.useRef() as React.MutableRefObject<FormInstance>;

  const history = useNavigate();

  const handleSubmit = () => {
    let values: iPass = formRef.current.getFieldsValue();

    if (values.newPassword !== values.confirmPassword) {
      OpenNotification('¡Contraseña!', "las contraseñas no coinciden", 'warn');
      return;
    }

    if (values.newPassword === "" || values.confirmPassword === "") {
      OpenNotification('¡Contraseña!', "Contraseña no valida", 'warn');
      return;
    }

    ChangeUserPassword(user.id, values.newPassword, values.password, (result) => {
      if (result > 0) {
        OpenNotification('¡Contraseña!', "Se actualizó la contraseña");
        values.password = ""
        user.pass = values.newPassword;
        _SetStudentInfo(user);

        history(-1);
      } else if (result == -3) {
        OpenNotification('¡Contraseña!', "Contraseña no coincide con el anterior", 'warn');
      } else {
        OpenNotification('¡Contraseña!', "Ocurrió un error al actualizar la contraseña", 'error');
      }

      formRef.current.resetFields();
    });
  }

  return (
    <Form layout='vertical' ref={formRef}>
      {user.pass && <FormItem
        label='Contraseña actual'
        name='password'
        initialValue={emptyPass.password}>
        <Input.Password
          name='password'
          placeholder='Contraseña actual' />
      </FormItem>}

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='newPassword'
            label='Nueva contraseña'
            initialValue={emptyPass.newPassword}
            rules={[{ required: true, message: 'ingresa la nueva contraseña' }]} >
            <Input.Password placeholder='Nueva contraseña' name='newPassword' />
          </FormItem>
        </div>

        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='confirmPassword'
            label='Confirmar contraseña'
            initialValue={emptyPass.confirmPassword}
            rules={[
              {
                required: true,
                message: 'Confirma tu contraseña!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Las contraseñas no coinciden!');
                }
              })
            ]} >
            <Input.Password name='confirmPassword' placeholder='Confirmar contraseña' />
          </FormItem>
        </div>
      </div>

      <Button type='primary' onClick={handleSubmit}>Guardar cambios</Button>
    </Form>
  );
};

const userDefault = {
  address: null,
  birthDate: null,
  description: null,
  email: null,
  gender: 1,
  inUse: true,
  lastname: null,
  name: null,
  matricula: 0,
  phone: null,
  typeUser: ETypeUser.STUDENT
} as IStudent;

const EditAccountStudent = () => {
  const location = useLocation();
  let _user = location.state as IStudent;

  if (!_user) pageData.title = 'Agregar alumno';
  else pageData.title = 'Editar alumno';
  if (_user && _user.id == USER_INFO.id) pageData.title = 'Editar cuenta';

  usePageData(pageData);

  if (!_user) _user = userDefault;

  const [user, setUser] = useState(_user);


  let showPassForm = !!user.id;

  return (
    <div className='stack' style={{ maxWidth: 690, margin: '0 auto' }}>
      {/* <UserAvatar className='mt-0' src={SetProfileImage(user.img as string)} /> */}
      <AccountForm user={user} fnUpdateUser={setUser} />

      <Divider />

      {showPassForm && (<PasswordForm user={user} />)}
    </div>
  );
};

export default EditAccountStudent;
