
import { IPageData } from '../../interfaces/page';
import { usePageData } from '../../hooks/usePage';
import { Avatar, Card, Timeline } from 'antd';
import { USER_INFO, SetProfileImage, _APP_ID, IsDiscapacidadProject } from '../../data/General';
import { IUser, mapTypeUsers } from '../../interfaces/user';
import { useLocation } from 'react-router-dom';

const pageData: IPageData = {
  title: 'Perfil de usuario',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Usarios',
      route: 'users'
    },
    {
      title: 'Perfil de usuario'
    }
  ]
};

const UserPreview = () => {
  const userInfo = USER_INFO;
  const userCover = `${window.origin}/content/user-profile.jpg`;
  const userAvatar = SetProfileImage(userInfo.img);

  //Agustar navegacion por appId
  if (IsDiscapacidadProject()) {
    pageData.breadcrumbs = [{
      title: 'Principal',
      route: 'home'
    },
    {
      title: 'Alumnos',
      route: 'students'
    },
    {
      title: 'Perfil de usuario'
    }]
  }

  return (
    <Card
      cover={<img src={userCover} alt='user-cover' />}
      className='personal-info-card with-shadow'>
      <div
        className='d-flex align-items-center justify-content-between'
        style={{ marginTop: '-50px' }}>
        <Avatar src={userAvatar} size={100} />

      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='mb-0 mt-0 mr-1'>{userInfo.name + ' ' + userInfo.lastname}</h5>
      </div>

      <p style={{ color: '#8f8f90' }}>Usuario {mapTypeUsers.get(userInfo.typeUser)}</p>

      <p>
        {userInfo.description}
      </p>
    </Card>
  );
};


const GeneralData = () => {
  const location = useLocation();
  const userInfo = location.state as IUser;
  console.log(userInfo)
  if (!userInfo)
    return (<></>);

  return (
    <Card title='Datos gennerales' className='mb-md-0 with-shadow'>
      <Timeline>
        <Timeline.Item color='gray'>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            Fecha de nacimiento
          </div>
          <span className='primary-text mb-4'>
            <strong>{userInfo.birthDate}</strong>
          </span>
        </Timeline.Item>

        <Timeline.Item color='gray'>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            Edad
          </div>
          <span className='primary-text mb-4'>
            <strong>{userInfo.age}</strong>
          </span>
        </Timeline.Item>

        <Timeline.Item color='gray'>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            Genero
          </div>
          <span className='primary-text mb-4'>
            <strong>{userInfo.gender === 1 ? 'Masculino' : (userInfo.gender === 0 ? 'Femenino' : "")}</strong>
          </span>
        </Timeline.Item>

        <Timeline.Item color='gray'>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            Fecha de creación
          </div>
          <span className='primary-text mb-4'>
            <strong>{userInfo.createdAt}</strong>
          </span>
        </Timeline.Item>

        <Timeline.Item color='gray'>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            Ultima actualización
          </div>
          <span className='primary-text mb-4'>
            <strong>{userInfo.updatedAt}</strong>
          </span>
        </Timeline.Item>
      </Timeline>




    </Card>
  );
};


const ContactInfo = () => {
  const userInfo = USER_INFO;

  return (
    <Card title='Información de contacto' className='mb-md-0 with-shadow'>
      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-ui-touch-phone' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Teléfono</div>
          {userInfo.phone}
        </div>
      </div>

      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-email' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Correo electrónico</div>
          {userInfo.email}
        </div>
      </div>

      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-location-pin' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Dirección</div>
          {userInfo.address ?? ' - '}
        </div>
      </div>
    </Card>
  );
};

const UserProfilePage = () => {
  usePageData(pageData);

  return (
    <div className='row'>
      <div className='col-md-3 col-sm-12'></div>
      <div className='col-md-6 col-sm-12'>
        <UserPreview />
        <GeneralData />
        <ContactInfo />
      </div>
    </div>
  );
};

export default UserProfilePage;
