import jsPDF from "jspdf";
import { IAppointment } from "../interfaces/patient";
import { IPdfText } from "./Entities";
import { AddImageToPDF, AddScalePainImageInPDF } from "./UtilsPDF";
import { DisabilityLevel, iDisabilityDescription, listValuesBack, listValuesNeck } from "../pages/appointments/DisabilityTable";

const _margin = 25;
var _height = 450;
var _width = 380;
var _widthArea = _width;
var _posInY = _margin;
const _fontSize = 12;
const _lineSpacing = 8;
const _logoWidth = 250;
const _logoHeight = 36;
const _colorFont = "#000";
const _colorSecundaryFont = "#696969";
const _colorLightGray = "#e8e8e8";


export const InitPDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment)
    // let uri = pdf.output("datauristring")
    let uri = pdf.output('blob')

    // pdf.save("pdf_test");
    // pdfViewerUpdate(uri);

    return uri;
}

export const SavePDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment);
    pdf.save("Cita_" + appointment.forDate);
}

async function fnPDFCreate(appointment: IAppointment) {
    let patient = appointment._patient;

    const METADATA = {
        title: 'Paciente: ' + patient.name,
        subject: 'Informacion de cita',
        author: 'Tino 710',
        keywords: 'Dr. pedro reyes, cita de paciente',
        creator: 'Dr. Admin'
    };

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
    });

    _width = pdf.internal.pageSize.getWidth();
    _height = pdf.internal.pageSize.getHeight();

    _widthArea = (_width - _margin * 2)
    _posInY = _margin;

    const infoPatient: Array<IPdfText> = [
        { label: "Información del paciente", value: null, posX: 0 },
        { label: "Nombre completo: ", value: patient.name, posX: _margin },
        { label: "Correo eléctronico: ", value: patient.email, posX: _margin },
        { label: "Teléfono: ", value: patient.phone, posX: _margin },
        { label: "Edad: ", value: "" + patient.age, posX: _margin, val2: { label: "Genero: ", value: patient.gender == 1 ? "Masculino" : "Femenino", posX: _width / 2 } },
        { label: null, value: null, posX: 0 },
        { label: "Información de la cita", value: null, posX: 0 },
        {
            label: "Fecha: ", value: appointment.createdAt, posX: _margin, val2: {
                label: "Tipo de dolor: ", value: appointment.painType == 1 ? 'Espalda' : (appointment.painType == 2 ? 'Cuello' : 'Ambos'), posX: _width / 2
            }
        },
        { label: "Descripción: ", value: appointment.description, posX: _margin },
        { label: "Mapa de dolor de frente: ", value: null, posX: _margin, val2: { label: "Mapa de dolor de espalda: ", value: null, posX: _width / 2 } },
    ];


    // >> METADATA
    pdf.setProperties(METADATA);
    pdf.setFontSize(_fontSize)

    pdf.addImage("/logo_main.png", 'PNG', (_width - _logoWidth) / 2, _posInY, _logoWidth, _logoHeight);
    _posInY += _logoHeight + 10;

    // >> MARGIN LINES
    pdf.setDrawColor("#E0E0E0");
    pdf.line(_margin, _posInY, _width - _margin, _posInY);

    _posInY += 30;

    // >> Info patient
    infoPatient.forEach(item => {
        if (item.label)
            AddTextAndValuePDF(pdf, item.label, item.value, item.posX, _posInY, _widthArea);

        if (item.val2)
            AddTextAndValuePDF(pdf, item.val2.label, item.val2.value, item.val2.posX, _posInY, _widthArea);

        _posInY += _fontSize + _lineSpacing;
    });

    let imgPainSize = 180;
    let elementNeck: Element;
    if (appointment.svgPainMap1) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap1);
        elementNeck = imgSvgElement.getElementById("assets_img_body_back_svg");

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: 0, y: _posInY })
    }

    if (appointment.svgPainMap2) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap2);
        if (elementNeck) imgSvgElement.append(elementNeck);

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: _width / 2, y: _posInY })
    }


    _posInY += 200;//Image height

    // >> Cuestions.....
    AddNewPage(pdf);
    pdf.setFontSize(9);

    let questionBack: Array<any> = appointment.questionBack ? JSON.parse(appointment.questionBack) : [];
    let questionNeck: Array<any> = appointment.questionNeck ? JSON.parse(appointment.questionNeck) : [];

    //Mostrar solo las 10 respuestas,,,, Esto porque hay un problema con los primeros registros que se duplicaban las respuestas
    if (questionBack.length > 10)
        questionBack = questionBack.splice(questionBack.length - 10, questionBack.length);
    if (questionNeck.length > 10)
        questionNeck = questionNeck.splice(questionNeck.length - 10, questionNeck.length);

    let middWidthArea = _widthArea / 2;
    if (questionBack.length > 0) {
        AddQuestionValues(pdf, questionBack, "Cuestionario dolor de espalda", 0, middWidthArea, 'BACK');
    }

    //Agregar cuestionario en la otra columna
    _posInY = _margin;
    if (questionNeck.length > 0) {
        let posX = questionBack.length > 0 ? middWidthArea : 0;
        AddQuestionValues(pdf, questionNeck, "Cuestionario dolor de cuello", posX, middWidthArea, 'NECK');
    }



    // Draw SCALE PAIN
    if (appointment.scalePain > -1) {
        AddNewPage(pdf);
        // _posInY = _height - 150;
        _posInY = _margin;
        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Escala de dolor EVA", null, 0, _posInY += _lineSpacing, middWidthArea);
        _posInY += _fontSize;

        await AddScalePainImageInPDF(pdf, _widthArea - _margin * 2, 42, _margin, _posInY, appointment.scalePain, _colorFont);
    }

    pdf.setFontSize(_fontSize)

    return pdf;
}

function AddQuestionValues(pdf: jsPDF, questions: Array<any>, title: string, posX: number, wdth: number, type: 'BACK' | 'NECK') {
    pdf.setFont(undefined, 'bold');
    AddTextAndValuePDF(pdf, title, null, posX, _posInY, wdth);
    _posInY += _fontSize * 2;
    let totalPoint = 0;

    pdf.setFont(undefined, 'normal');
    for (let i = 0; i < questions.length; i++) {
        let item = questions[i];
        const resp = "(" + (item.score + 1) + ") " + item.v;
        AddTextQuestionPDF(pdf, item.q, resp, posX + _margin);
        totalPoint += (item.score || 0);
    }

    // ADD table result value
    let descValue = `El valor total es de ${totalPoint}, su nivel de capacidad es "${DisabilityLevel(totalPoint)}".  `;
    if (type == 'BACK') descValue += `El porcentaje de discapacidad es ${Math.round(totalPoint / 50 * 100)}%.`

    const tableTitle = type == 'BACK' ? "porcentajes ODI:" : "Categorías de nivel de incapacidad";
    const listVls = type == 'BACK' ? listValuesBack : listValuesNeck;

    AddTableValues(pdf, posX, _posInY, wdth, descValue, tableTitle, listVls);
}

function AddTextAndValuePDF(pdf: jsPDF, label: string, value: string, margin: number, posY: number, width: number) {
    margin += _margin;
    pdf.setTextColor(_colorFont);
    pdf.text(label, margin, posY, { maxWidth: width });

    if (value) {
        pdf.setTextColor(_colorSecundaryFont);
        pdf.text(value, margin + pdf.getTextWidth(label), posY, { maxWidth: width })
    }

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);  // Restart height position
    }
}

function AddTextQuestionPDF(pdf: jsPDF, question: string, response: string, posX: number) {
    let middlePage = _widthArea / 2;

    pdf.setTextColor(_colorFont);
    pdf.text(question, posX, _posInY, { maxWidth: middlePage });

    _posInY += _fontSize + _lineSpacing * 0.25;
    pdf.setTextColor(_colorSecundaryFont);
    pdf.text(response, posX, _posInY, { maxWidth: middlePage })

    _posInY += _fontSize + _lineSpacing;

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);
    }
}

function GetSVGImageEelement(svgInner: string) {
    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    iconSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    iconSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink")
    iconSvg.setAttribute('fill', '#FFF');
    iconSvg.setAttribute('viewBox', '0 0 400 400');
    iconSvg.setAttribute('width', '400');
    iconSvg.setAttribute('height', '400');
    iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    //Quitar classes
    svgInner = svgInner.replaceAll(`class="line-pain"`, `stroke-width="5px" stroke-linecap="round" cursor="pointer"`)

    iconSvg.innerHTML = svgInner;

    return iconSvg;
}

function AddNewPage(pdf: jsPDF) {
    pdf.addPage();
    _posInY = _margin;  // Restart height position
}


function AddTableValues(pdf: jsPDF, posX: number, posY: number, wdth: number, descriptionResult: string, title: string, listValues: iDisabilityDescription[]) {
    pdf.setFont(undefined, 'bold');
    AddTextAndValuePDF(pdf, descriptionResult, null, posX + 5, posY + _fontSize, wdth - 10);
    pdf.setFont(undefined, 'normal');
    posY += 32;

    const hgtRow = 20;
    const mrgn = 5;
    pdf.setFillColor(_colorLightGray);
    pdf.rect(posX + _margin, posY, wdth - 5, hgtRow, 'F');
    AddTextAndValuePDF(pdf, title, null, posX + mrgn, posY += _fontSize, wdth - mrgn * 2);

    for (let i = 0; i < listValues.length; i++) {
        const item = listValues[i];
        posY += hgtRow + mrgn;

        let wthCell = wdth * 0.2;
        AddTextAndValuePDF(pdf, item.values, null, posX + mrgn, posY, wthCell);
        wthCell = wdth * 0.3;
        if (!item.description) wthCell = wdth * 0.8;
        AddTextAndValuePDF(pdf, item.name, null, posX + (wdth * 0.2) + mrgn, posY, wthCell - mrgn * 2);
        wthCell = wdth * 0.5;
        if (item.description)
            AddTextAndValuePDF(pdf, item.description, null, posX + wthCell + mrgn, posY, wthCell - mrgn * 2);
    };
}